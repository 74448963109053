import React, { useEffect, useState } from 'react';
import './Torneo.css';

export function Torneo() {
  const [teams, setTeams] = useState<string[][]>([]);
  const [schedule, setSchedule] = useState<string[][][]>([]);
  const [winners, setWinners] = useState<string[][]>([[], [], []]);

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('tournamentConfig') || '{}');
    const numTeams = parseInt(config.cantidadEquipos, 10) || 0;
    const teamsData = JSON.parse(localStorage.getItem('teamsData') || '[]');

    if (numTeams > 0 && teamsData.length > 0) {
      setTeams(teamsData);
    }
  }, []);

  useEffect(() => {
    const generateSchedule = () => {
      if (teams.length < 2) {
        return;
      }

      const roundMatches = [];
      const teamOne = [...teams[0]];
      const teamTwo = [...teams[1]];

      while (teamOne.length > 0 && teamTwo.length > 0) {
        const playerOne = teamOne.shift();
        const playerTwo = teamTwo.shift();

        if (playerOne && playerTwo) {
          roundMatches.push([playerOne, playerTwo]);
        }
      }

      setSchedule([roundMatches]);
    };

    generateSchedule();
  }, [teams]);

  const handlePlayerClick = (roundIndex: number, matchIndex: number, player: string) => {
    const updatedWinners = [...winners];
    if (!updatedWinners[roundIndex]) {
      updatedWinners[roundIndex] = [];
    }
    updatedWinners[roundIndex][matchIndex] = player;

    setWinners(updatedWinners);

    if (roundIndex < 2 && updatedWinners[roundIndex].length === schedule[roundIndex].length) {
      const nextRoundMatches = [];
      for (let i = 0; i < updatedWinners[roundIndex].length; i += 2) {
        nextRoundMatches.push([updatedWinners[roundIndex][i], updatedWinners[roundIndex][i + 1]]);
      }
      const updatedSchedule = [...schedule];
      updatedSchedule[roundIndex + 1] = nextRoundMatches;
      setSchedule(updatedSchedule);
    }
  };

  return (
    <div className='torneo_container'>
        <div className="torneo">
        {schedule.map((round, roundIndex) => (
            <div className={`round_${roundIndex + 1}`} key={roundIndex}>
              <h3>{roundIndex == 0 ? "-- Cuartos --" : roundIndex == 1 ? "-- Semifinales --" : "-- Final --" }</h3>
            {round.map((match, matchIndex) => (
                <div className="match" key={matchIndex}>
                {match.map((player, playerIndex) => (
                    <button
                    className="player"
                    key={playerIndex}
                    onClick={() => handlePlayerClick(roundIndex, matchIndex, player)}
                    >
                    {player}
                    </button>
                ))}
                {roundIndex === schedule.length - 1 && (
                    <div className='winner'>
                    
                    {winners[roundIndex][matchIndex]}
                    </div>
                )}
                </div>
            ))}
            </div>
        ))}
        </div>
    </div>
  );
}
