import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { Home } from "../Sections/Home";
import { Torneo } from "../Sections/Torneo";

export function AppRouter(){
    

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/Torneo" element={<Torneo/>}/>
            </Routes>
            
        </Router>
    )
}