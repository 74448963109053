import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // Importa el archivo de estilos

export function Home() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();
  const [videoEnded, setVideoEnded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isNotEmpty, setIsNotEmpty] = useState(false);
  const [config, setConfig] = useState({ tipo: '', cantidadEquipos: '', equiposParticipando: '' });
  const [teamsData, setTeamsData] = useState<string[][]>([]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.onended = () => {
        setVideoEnded(true);
      };
    }
  }, []);

  const handleNewTournament = () => {
    localStorage.setItem('tournamentConfig', JSON.stringify(config));
    localStorage.setItem('teamsData', JSON.stringify(teamsData));
    navigate('/Torneo');
  };

  const handlePlayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value
    });
  };

  const handleTeamChange = (teamIndex: number, clubIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTeamsData((prevTeamsData) => {
      const updatedTeams = [...prevTeamsData];
      if (!updatedTeams[teamIndex]) {
        updatedTeams[teamIndex] = [];
      }
      updatedTeams[teamIndex][clubIndex] = value;
      return updatedTeams;
    });
  };

  const renderSelection = (teamIndex: number) => {
    const numSel = parseInt(config.equiposParticipando, 10) || 0;
    return Array.from({ length: numSel }, (_, j) => (
      <div key={j} className='team'>
        <div className='input-group'>
          <label htmlFor={`equipo-${teamIndex}-${j}`}>Club/Seleccion: </label>
          <input
            type="text"
            name={`equipo-${teamIndex}-${j}`}
            id={`equipo-${teamIndex}-${j}`}
            className='text-input'
            onChange={handleTeamChange(teamIndex, j)}
          />
        </div>
      </div>
    ));
  };

  const renderTeams = () => {
    const numTeams = parseInt(config.cantidadEquipos, 10) || 0;
    return Array.from({ length: numTeams }, (_, i) => (
      <div key={i} className='team'>
        <h2>Equipo {i + 1}</h2>
        {renderSelection(i)}
      </div>
    ));
  };

  useEffect(() => {
    const { tipo, cantidadEquipos, equiposParticipando } = config;
    if (tipo && cantidadEquipos && equiposParticipando) {
      setIsNotEmpty(true);
    } else {
      setIsNotEmpty(false);
    }
  }, [config]);

  return (
    <div className="video-container">
      <video ref={videoRef} className="video-background">
        <source src={require('../assets/champions.mp4')} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <button
          className="play-video-button btn-white"
          onClick={handlePlayVideo}
        >
          Nuevo Torneo
        </button>
      )}
      {videoEnded && (
        <div className="new-tournament-config">
          <div className='form-group'>
            <div className='input-group'>
              <label htmlFor="tipo">Tipo de torneo: </label>
              <select name="tipo" id="tipo" className='tipo-torneo form-input' onChange={handleChange} value={config.tipo}>
                <option value="">Selecciona</option>
                <option value="2">Torneo por Equipos</option>
              </select>
            </div>
            <div className='input-group'>
              <label htmlFor="cantidadEquipos">Equipos: </label>
              <select name="cantidadEquipos" id="cantidadEquipos" className='cantidad-equipos form-input' onChange={handleChange} value={config.cantidadEquipos}>
                <option value="">Selecciona</option>
                <option value="2">2</option>
              </select>
            </div>
            <div className='input-group'>
              <label htmlFor="equiposParticipando">Clubes/Selecciones por equipo: </label>
              <select name="equiposParticipando" id="equiposParticipando" className='cantidad-equipos form-input' onChange={handleChange} value={config.equiposParticipando}>
                <option value="">Selecciona</option>
                <option value="2">2</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
          <div className='Equipos-select form-group'>
            {isNotEmpty &&        
              <div className='form-group'>
                {renderTeams()}
              </div>
            }
          </div>

        </div>
      )}
      {isNotEmpty &&        
            <button className='btn-continue' onClick={handleNewTournament} disabled={!isNotEmpty} >
              Comenzar
          </button>
            }
        
    </div>
  );
}
